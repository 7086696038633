import React from 'react'
import Grid from '@mui/material//Grid'
import Typography from '@mui/material/Typography'
import Layout from '../components/layout'
import SEO from '../components/seo'

const ContactPage = () => {
  return (
    <Layout>
      <SEO title="Contact" />
      <Grid container justifyContent='center'>
        <Grid 
          container 
          direction='column' 
          alignItems='center'
          sx={{maxWidth: '35rem'}} 
          spacing={3}
        >
          <Grid item>
            <Typography paragraph={true} sx={{marginTop: '1.5rem'}}>
              You can reach me by email at nathan.gould@gmail.com. I also keep my DM's open on Twitter.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default ContactPage